/* CONTENT STYLING */
/* .ting-content {
  position: relative;
}
.ting-content > div:before {
  content: "*";
  color: #a0aec0;
  position: absolute;
} */
@font-face {
  font-family: "PT sans";
  font-style: normal;
  font-weight: 400;
  src: local("PT sans"), local("PT-sans"),
    url(./fonts/PTSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "PT sans";
  font-style: bold;
  font-weight: 700;
  src: local("PT sans"), local("PT-sans"),
    url(./fonts/PTSans-Bold.ttf) format("truetype");
}

html {
  scroll-behavior: smooth;
  background-color: #fff;
  transition: background-color ease-in 100ms;
}

.header {
  backdrop-filter: blur(3px);
}

.note--open {
  background-color: #f7fafc;
}

.note--open .header-wrapper {
  background-color: #f7fafc;
  opacity: 0.75;
}

.ting-content {
  font-family: "PT sans", Arial, Helvetica, sans-serif;
  /* color: #222; */
}

.ting-content h1,
.ting-content h2,
.ting-content h3,
.ting-content ul,
.ting-content ol,
.ting-content p {
  margin-bottom: 1rem;
}

.ting-content p:last-child {
  margin-bottom: 0rem;
}

.ting-content h1 {
  font-size: 1.25rem;
  font-weight: bold;
}
.ting-content h2 {
  font-size: 1.125rem;
  font-weight: bold;
}
.ting-content h3 {
  font-size: 1.1rem;
  font-weight: bold;
}

.ting-content ol {
  list-style: none;
  counter-reset: item;
  margin-left: 0rem;
}

.ting-content ul {
  list-style: disc;
  margin-left: 1rem;
}

.ting-content ol li:before {
  display: inline-block;
  content: counter(item) ".";
  counter-increment: item;
  color: #a0aec0;
  margin-right: 1rem;
}

.ting-content a,
.ting-content a:link {
  color: #48bb78;
}
.truncate :not(:first-child) {
  display: none;
}
.truncate :first-child {
  margin: 0;
}
.truncate p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* 
a[aria-current="page"] {
  color: #000000;
  border-bottom: 2px solid;
} */

.ting-header,
.ting-header .ting-container {
  font-size: 1.5rem;
}

.ting-header a::before,
.ting-header a:link::before,
.ting-container a::before,
.ting-container a:link::before {
  content: "[[";
  color: #cbd5e0;
}
.ting-header a::after,
.ting-header a:link::after,
.ting-container a::after,
.ting-container a:link::after {
  content: "]]";
  color: #cbd5e0;
}

#editor--wrapper:focus-within,
#header--search:focus-within {
  border-color: #68d391;
}

.editor-input__suggestions__item:last-child::before {
  content: "create:";
  font-size: 10px;
  font-weight: 500;
  vertical-align: middle;
  padding-right: 5px;
  color: #48bb78;
  text-transform: uppercase;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
